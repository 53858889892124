import React from "react"
import DataStore from "../stores/DataStore";
import buttonRight from '../../gfx/button_right.png';
import buttonClose from '../../gfx/button_close.png';
import * as DataActions from "../actions/DataActions";

var Constants = require('../Constants');

export default class DropMenuSearchResult extends React.Component {


	constructor() {
		super();
		this.state = {
			productListCountLabel: DataStore.getProductListCountLabel()

		}
	}



	componentDidMount() {

		DataStore.on(Constants.PRODUCT_LIST_CHANGED, () => { this.setup() })
		DataStore.on(Constants.SEARCH_RESULT_CHANGED, () => { this.setup() })
	}

	onClick() {
		DataActions.dropMenuCategoryVisibility(false);
		DataActions.dropMenuManufactorVisibility(false);
	}

	setup() {
		this.setState({ productListCountLabel: DataStore.getProductListCountLabel() })
	}

	render() {

		var className = this.props.visible ? 'dropMenuManufactor displayBlock' : 'dropMenuManufactor displayBlock';

		var productCount = DataStore.getFilteredProductListUnlimitedLength();

		var title = ""

		if (productCount == 0) {
			title = "Hittade inga produkter att visa"
		} else if (productCount == 1) {
			title = "Visa en produkt"
		} else if (productCount > 1) {
			title = "Visa " + productCount + " produkter";
		}

		return (
			<div className='dropMenuSearchResult' onClick={this.onClick.bind(this)} title={title}><h3>{this.state.productListCountLabel} </h3>
				<img src={productCount > 0 ? buttonRight : buttonClose} />

			</div>
		)
	}
}